import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TokenService } from './shared/token.service';
import { AuthStateService } from './shared/auth-state.service';

import { IconSetService } from '@coreui/icons-angular';
import { freeSet } from '@coreui/icons';
import { AuthService } from './shared/auth.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>',
  providers: [IconSetService],
})
export class AppComponent implements OnInit {
  isSignedIn: boolean;
  user: string;



  constructor(
    private auth: AuthStateService,
    public token: TokenService,
    private router: Router,
    public iconSet: IconSetService,
    public authService: AuthService,
    public translate: TranslateService
  ) {
    translate.addLangs(['fr', 'ar']);
    //localStorage.setItem('lang','en');
    //if(localStorage.getItem('lang')){ translate.use(localStorage.getItem("lang"));}
    //else{translate.use('fr'), localStorage.setItem('lang','fr')}



    // iconSet singleton
    iconSet.icons = { ...freeSet };
  }

  ngOnInit() {
   // if(localStorage.getItem('lang')){ this.translate.use(localStorage.getItem("lang"));}
    //else{this.translate.use('fr'), localStorage.setItem('lang','fr')}
    this.getWithExpiry("ttl");
    this.user = localStorage.getItem("user");
    if(!this.user){
      this.logout();
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  logout() {
    this.auth.setAuthState(false);
    this.token.removeToken();
    localStorage.removeItem('lang');
    localStorage.removeItem('user');
    localStorage.removeItem('permission');
    localStorage.removeItem("side_bar");
    this.router.navigate(['login']);
  }


  getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key)
      this.logout();
    }
    return item.value
  }
}
