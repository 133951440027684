import { navItemsCadorimPay } from "./../../nav/_nav_cadorimpay";
import { navItems3, navItems3Ar } from "./../../nav/_nav3";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthStateService } from "../../shared/auth-state.service";
import { AuthService } from "../../shared/auth.service";
import { TokenService } from "../../shared/token.service";
import { navItems } from "../../nav/_nav";
import { navItems2, navItems2Ar } from "../../nav/_nav2";
import { navItems1 } from "../../nav/_nav1";
import { navItemsGestions } from "../../nav/_nav_gestions";
import { TranslateService } from "@ngx-translate/core";
import { navItemsKyc } from "../../nav/_nav_kyc";
import { navSuperviseur } from "../../nav/_nav_superviseur";
import { navSaleh } from "../../nav/_nav_saleh";
import { Auth2Service } from "../../shared2/auth2.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html",
})
export class DefaultLayoutComponent implements OnInit {
  public sidebarMinimized = false;
  public navItems: any;
  isSignedIn: boolean;
  d;
  user;
  t: string;
  array;
  type: any;
  account: any;
  name: any;
  last: any;
  lang: string = "";
  textDir: string;
  date;
  text: any = "rtgh";
  walletPermission: any;
  constructor(
    public authService: AuthService,
    public authService2: Auth2Service,
    private auth: AuthStateService,
    public token: TokenService,
    private router: Router,
    public translate: TranslateService
  ) {
    translate.addLangs(["fr", "ar"]);
    if (localStorage.getItem("lang")) {
      this.translate.use(localStorage.getItem("lang"));
    } else {
      this.translate.use("fr"), localStorage.setItem("lang", "fr");
    }
    this.sideBar();
    this.type = JSON.parse(localStorage.getItem("user")).user_type;
    this.name = JSON.parse(localStorage.getItem("user")).name;
    this.last = JSON.parse(localStorage.getItem("user")).lastname;
  }
  async ngOnInit() {
    this.date = Date();
    this.lang = localStorage.getItem("lang");
    if (this.lang.toString() == "ar") {
      this.textDir = "rtl";
    } else {
      this.textDir = "ltr";
    }
    this.translate.use(localStorage.getItem("lang"));
    this.t = localStorage.getItem("ttl");
    this.user = localStorage.getItem("user");
    if (!this.user) {
      this.logout();
    }
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    this.auth.setAuthState(false);
    this.token.removeToken();
    localStorage.removeItem("lang");
    localStorage.removeItem('user');
    localStorage.removeItem('permission');
    localStorage.removeItem("side_bar");
    this.router.navigate(["login"]);
  }

  switchLang(lang: string) {
    this.translate.use(lang);
    localStorage.setItem("lang", lang);
    if (JSON.parse(localStorage.getItem("user")).user_type == "operateur") {
      if (localStorage.getItem("lang") == "ar") {
        this.navItems = navItems3Ar;
      } else {
        this.navItems = navItems3;
      }
    } else if (
      JSON.parse(localStorage.getItem("user")).user_type == "caissier principal"
    ) {
      if (localStorage.getItem("lang") == "ar") {
        this.navItems = navItems2Ar;
      } else {
        this.navItems = navItems2;
      }
    }

    this.router.navigateByUrl("/");
  }


   parseMenu(menuString: string): MenuItem[] {
    return menuString
      .split(/\s*,\s*/) // Permet d'éliminer les espaces et les sauts de ligne après la virgule
      .filter(item => item.trim() !== "")
      .map(item => {
        const parts = item.split(":");
        return {
          name: parts[0].trim(),
          url: parts[1].trim(),
          icon: parts.slice(2).join(":").trim() // Prend tout ce qui vient après `url`
        };
      });
  }
  async sideBar() {
    return new Promise<void>((resolve, reject) => {
      try {


        let side_bar = localStorage.getItem("side_bar");
        console.log(side_bar);
        const menuItems: MenuItem[] = this.parseMenu(side_bar);
        console.log(menuItems);
        this.navItems = menuItems;
        //    let side_bar = JSON.parse(localStorage.getItem("side_bar"));
        // if (
        //   JSON.parse(localStorage.getItem("user")).user_type ==
        //   "caissier principal"
        // ) {
        //   if (localStorage.getItem("lang") == "ar") {
        //     this.navItems = navItems2Ar;
        //   } else {
        //     this.navItems = navItems2;
        //   }
        // } else if (
        //   JSON.parse(localStorage.getItem("user")).user_type == "superviseur"
        // ) {
        //   this.navItems = navSuperviseur;
        // } else if (
        //   JSON.parse(localStorage.getItem("user")).user_type == "gestionnaire"
        // ) {
        //   this.navItems = navItemsGestions;
        // } else if (
        //   JSON.parse(localStorage.getItem("user")).user_type == "operateur" &&
        //   JSON.parse(localStorage.getItem("user")).email != "saleh@cadorim.com"
        // ) {
        //   if (localStorage.getItem("lang") == "ar") {
        //     this.navItems = navItems3Ar;
        //   } else {
        //     this.navItems = navItems3;
        //   }
        // } else if (
        //   JSON.parse(localStorage.getItem("user")).user_type == "operateur" &&
        //   JSON.parse(localStorage.getItem("user")).email == "saleh@cadorim.com"
        // ) {
        //   this.navItems = navSaleh;
        // } else if (
        //   JSON.parse(localStorage.getItem("user")).user_type == "admin"
        // ) {
        //   // navItems1.forEach(element => {
        //   //   console.log(element?.badge?.text);
        //   // });
        //   // navItems1.push();

        //   this.navItems = navItems1;
        // } else if (
        //   JSON.parse(localStorage.getItem("user")).user_type == "comptable"
        // ) {
        //   this.navItems = navItems;
        // } else if (
        //   JSON.parse(localStorage.getItem("user")).user_type == "business"
        // ) {
        //   this.navItems = navItemsCadorimPay;
        // } else if (
        //   JSON.parse(localStorage.getItem("user")).user_type == "kyc"
        // ) {
        //   this.navItems = navItemsKyc;
        // } else {
        //   this.logout();
        // }
        // if (
        //   localStorage.getItem("permission") &&
        //   localStorage.getItem("permission") == "1"
        // ) {
        //   this.navItems.splice(4, 0, {
        //     name: "Transaction wallet",
        //     url: "/operations/wallet-transaction",
        //     icon: "icon-share-alt",
        //   });
        // }

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  // async getWalletPermission(id) {
  //   return new Promise<void>((resolve, reject) => {
  //     try {
  //       this.authService2.getData("get-wallet-permission/" + id).subscribe(
  //         (data: any) => {
  //           this.walletPermission = data.permission;
  //           if (this.walletPermission == 1) {
  //             console.log(data);
  //             this.navItems.splice(4, 0, {
  //               name: "Transaction wallet",
  //               url: "/operations/wallet-transaction",
  //               icon: "icon-share-alt",
  //             });
  //           }
  //         },
  //         (error) => {},
  //         () => {}
  //       );
  //       resolve();
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // }
}

interface MenuItem {
  name: string;
  url: string;
  icon: string;
}
