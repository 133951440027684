import { INavData } from '@coreui/angular';

export const navItems2: INavData[] = [
  {
    name: 'Accueil',
    url: '/dashboard',
    icon: 'icon-home',
  },
      {
        name: 'Depot',
        url: '/operations/depots',
        icon: 'icon-share-alt'
      },

      {
        name: 'Retrait',
        url: '/operations/retraits',
        icon: 'icon-share-alt'
      },

      {
        name: 'Rapport transactions',
        url: '/situation/transaction',
        icon: 'fa fa-bar-chart',
      },

      {
        name: 'Rapport quotidien',
        url: '/operations/rapport-quotidien',
        icon: 'icon-notebook'
      },

      {
        name: 'Soldes client',
        url: '/cash/solde-client',
        icon: 'icon-notebook'
      },

      {
        name: 'Soldes operateurs',
        url: '/cash/operateur-solde',
        icon: 'icon-notebook'
      },

      {
        name: 'Gestion de paiement',
        url: '/operations',
        icon: 'fa fa-bank',
        children: [
          {
            name: ' Paiement en attente',
            url: '/operations/pending-payment',
            icon: 'fa fa-history',
          },
         /* {
            name: 'Demande CCP',
            url: '/cadorimpay/ccp-order',
            icon: 'fa fa-history',
          },*/
        ]
        },
      
        {
        name: 'Mon journal',
        url: '/cash/accounts-journal',
        icon: 'icon-notebook'
      },

      {
        name: 'Demande de fonds',
        url: '/operations/fund-orders',
        icon: 'fa fa-money',
      },
      


 
];

export const navItems2Ar: INavData[] = [
  {
    name: 'الرئيسية',
    url: '/dashboard',
    icon: 'icon-home',
  },
      {
        name: 'إيداع الأموال',
        url: '/operations/depots',
        icon: 'icon-share-alt'
      },

      {
        name: 'سحب الأموال',
        url: '/operations/retraits',
        icon: 'icon-share-alt'
      },

      {
        name: 'Rapport transactions',
        url: '/situation/transaction',
        icon: 'fa fa-bar-chart',
      },

        {
        name: 'Rapport quotidien',
        url: '/operations/rapport-quotidien',
        icon: 'icon-notebook'
      },

      {
        name: 'Soldes client',
        url: '/cash/solde-client',
        icon: 'icon-notebook'
      },

      {
        name: 'Soldes operateurs',
        url: '/cash/operateur-solde',
        icon: 'icon-notebook'
      },


      {
        name: 'إدارة الدفع',
        url: '/operations',
        icon: 'fa fa-bank',
        children: [
          {
            name: 'طلب الدفع قيد التقدم',
            url: '/operations/pending-payment',
            icon: 'fa fa-history',
          },
         /* {
            name: 'طلب حساب CCP',
            url: '/cadorimpay/ccp-order',
            icon: 'fa fa-history',
          },*/
        ]
        },
      
      
        {
        name: 'سجل معاملاتي',
        url: '/cash/accounts-journal',
        icon: 'icon-notebook'
      },

      {
        name: 'طلبات النقدية',
        url: '/operations/fund-orders',
        icon: 'icon-notebook',
      },



 
];
