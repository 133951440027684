import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class Auth2Service {
  constructor(private http: HttpClient) {}

  //url = "http://127.0.0.1:8000/api/auth/"; //environment.apiUrl;//local
  //urlimg = "http://127.0.0.1:8000/img"; //environment.apiUrlImg;//local

  url = "https://api-gestion.cadorim.com/api/auth/"; //environment.apiUrl;//Prod
  urlimg = "https://api-gestion.cadorim.com/img"; //environment.apiUrlImg;//Prod

  // url = "https://cadorim-dev.cadorim.com/api/auth/"; //environment.apiUrl;//dev-Accounting
  // urlimg = "https://cadorim-dev.cadorim.com/img/"; //environment.apiUrlImg;//dev-Accounting

  // User registration
  demande(data): Observable<any> {
    return this.http.post(this.url + "api2/demande", data);
  }

  Validerdemande(data): Observable<any> {
    return this.http.post(this.url + "api2/validerDemande", data);
  }

  histo(data?): Observable<any> {
    return this.http.post(this.url + "api2/euroHisto", data);
  }

  journal_eur(data?): Observable<any> {
    return this.http.post(this.url + "api2/journal_eur", data);
  }

  remboursement(date?): Observable<any> {
    return this.http.post(this.url + "remboursement", date);
  }

  remboursementconfirmation(id): Observable<any> {
    return this.http.post(this.url + "remboursementconfirmation", { id: id });
  }

  transaction(data): Observable<any> {
    return this.http.post(this.url + "api2/transaction", data);
  }

  benefContacted(id): Observable<any> {
    return this.http.post(this.url + "api2/colled", { id: id });
  }

  index(): Observable<any> {
    return this.http.get(this.url + "app2/all");
  }

  paypal(): Observable<any> {
    return this.http.get(this.url + "paypal");
  }

  paypal_details(date): Observable<any> {
    return this.http.post(this.url + "paypal-details", { date: date });
  }

  paypal_search(data): Observable<any> {
    return this.http.post(this.url + "paypal-search", data);
  }

  create(data): Observable<any> {
    return this.http.post(this.url + "app2/createAccount", data);
  }

  app(data): Observable<any> {
    return this.http.post(this.url + "app2/opp", data);
  }

  getCash(data?): Observable<any> {
    return this.http.post(this.url + "app2/getCash", data);
  }

  getCashsDay(data?): Observable<any> {
    return this.http.post(this.url + "app2/getCashsDay", data);
  }

  getCashFrais(data?): Observable<any> {
    return this.http.post(this.url + "app2/getCashFrais", data);
  }

  getCashsDayFrais(data?): Observable<any> {
    return this.http.post(this.url + "app2/getCashsDayFrais", data);
  }

  account(): Observable<any> {
    return this.http.get(this.url + "app2/all2");
  }

  // All status of providers
  providerStatus(): Observable<any> {
    return this.http.get(this.url + "api2/providers_status");
  }

  // Change status of provider after event
  changeProviderStatus(value): Observable<any> {
    return this.http.post(this.url + "api2/changestatus", value);
  }

  tempo(): Observable<any> {
    return this.http.get(this.url + "api2/tempo");
  }

  tempo_details(date): Observable<any> {
    return this.http.post(this.url + "api2/tempo-details", { date: date });
  }

  tempo_search(data): Observable<any> {
    return this.http.post(this.url + "api2/tempo-search", data);
  }

  getData(api_url): Observable<any> {
    return this.http.get(this.url + api_url);
  }

  PostData(api_url, data): Observable<any> {
    return this.http.post(this.url + api_url, data);
  }
}
